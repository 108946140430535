/*******************************
     User Global Variables
*******************************/
/*@pageMinWidth  : 1049px;*/
/*@pageOverflowX : visible;*/
/*
@bigSize : (18 / 13);

@h1: 2.25em;

@defaultBorderRadius: 0.2307em;

@disabledOpacity: 0.3;
*/
/* Colors */
/* Links */
/* Borders */
/*
@borderColor: rgba(0, 0, 0, 0.13);
@solidBorderColor: #DDDDDD;
@internalBorderColor: rgba(0, 0, 0, 0.06);
@selectedBorderColor: #51A7E8;
*/
/* Breakpoints */
/*@largeMonitorBreakpoint: 1049px;

@computerBreakpoint: @largeMonitorBreakpoint;
@tabletBreakpoint: @largeMonitorBreakpoint;
*/
/*
@infoTextColor: #4E575B;
@warningTextColor: #613A00;
@errorTextColor: #991111;
*/
.ReportViewer {
  width: 100%;
  height: 100%;
  background-color: white;
}
.ReportViewer .dataset-name {
  vertical-align: center;
  font-size: 1.25em;
  float: left;
  clear: left;
  display: inline-block;
}
.ReportViewer .filters-block {
  float: left;
  display: inline-block;
}
.ReportViewer .filter-and-label-block {
  width: 16.65%;
  float: left;
}
.ReportViewer .filter-label {
  width: 100%;
  font-size: medium;
  margin: 0px;
}
.ReportViewer .filter-values-block {
  width: 100%;
}
.ReportViewer .date-picker-with-icon {
  width: 100%;
  color: #0076b6;
  cursor: pointer;
}
.ReportViewer .date-picker-icon {
  float: left;
}
.ReportViewer .date-picker-alignment {
  width: 80%;
  float: left;
}
.ReportViewer .header {
  width: 100%;
  text-align: left;
  border-bottom: solid #ddd 2px;
  display: inline-block;
  padding: 3px;
}
.react-datepicker-popper {
  z-index: 3;
}
.ReportViewer > * > .react-datepicker__input-container {
  width: 100px;
}
.names {
  width: 25%;
  display: inline-block;
  float: left;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.report-name {
  vertical-align: center;
  font-size: 1.5em;
  float: left;
  display: inline-block;
}
.lastEdited {
  vertical-align: center;
  font-size: medium;
  color: gray;
  float: left;
  margin: 8px;
}
div.button-block {
  display: inline-block;
  float: left;
}
#fm-toolbar-wrapper {
  height: 58px !important;
}
#fm-toolbar-wrapper #fm-toolbar li a svg {
  height: 30px;
}
#fm-toolbar-wrapper #fm-toolbar a {
  height: 63px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-right > li > a span {
  top: 40px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-left > li > a span {
  top: 40px !important;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content a {
  height: 37px !important;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown {
  top: 63px !important;
}
div.ReportViewer * > div > input {
  border-color: #0076b6;
  border-radius: 4px;
  border-width: 1px;
  box-sizing: border-box;
  border-style: solid;
  box-shadow: rgba(0, 0, 0, 0);
  width: 80%;
}
