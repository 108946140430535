
.Breadcrumb {
  width:100%;
  height:100%;

  a:hover {
    text-decoration: none;
  }
}

//.Breadcrumb > * > a:hover{
//  text-decoration: none;
//}

.nav-link {
  display:inline;
}
