//$primaryColor: #0076b6;
//$primaryTextColor: #FFFFFF;
//$primaryBackgroundImage: none;
//$primaryTextShadow: none;
//$primaryBoxShadow: 0 0 0 0;
//$primaryColorHover: $primaryColor;
@import 'src/semantic-ui/site/globals/site.variables';

.ReportViewer {
  width:100%;
  height:100%;
  background-color: white;



  .dataset-name {
    vertical-align: center;
    font-size: 1.25em;
    float:left;
    clear:left;
    display: inline-block;
  }

  .filters-block {
    //width:37.5%;
    float:left;
    display: inline-block;
  }

  .filter-and-label-block {
    width:16.65%;
    float:left;
  }

  .filter-label {
    width:100%;
    font-size: medium;
    margin:0px;
  }

  .filter-values-block {
    width: 100%;
  }

  .date-picker-with-icon {
    width:100%;
    color:@cayuseBlue;
    cursor:pointer;
  }

  .date-picker-icon {
    float:left;
  }

  .date-picker-alignment {
    width:80%;
    float:left;
  }





}

.ReportViewer .header {
  width:100%;
  text-align:  left;
  border-bottom: solid #ddd 2px;
  display: inline-block;
  padding:3px;
}

.react-datepicker-popper {
  z-index: 3;
}

.ReportViewer > * > .react-datepicker__input-container {
  width:100px;
}

.names {
  width: 25%;
  display: inline-block;
  float:left;
  padding-left:8px;
  padding-right:8px;
  margin-top: .75em;
  margin-bottom: .75em;
}

.report-name {
  vertical-align: center;
  font-size: 1.5em;
  float:left;
  display: inline-block;
  //border-bottom-color: @cayuseBlue;
  //border-bottom-style: solid;
  //border-bottom-width: 0.05em;
}

.lastEdited {
  vertical-align: center;
  font-size: medium;
  color: gray;
  float:left;
  margin:8px;
}

div.button-block {
  //width:37.5%;
  display: inline-block;
  float:left;
}

#fm-toolbar-wrapper {
  height: 58px !important;
}
#fm-toolbar-wrapper #fm-toolbar li a svg {
  height: 30px;
}
#fm-toolbar-wrapper #fm-toolbar a {
  height: 63px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span, #fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-right > li > a span {
  top: 40px !important;
}
#fm-toolbar-wrapper #fm-toolbar > li > a span, #fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-left > li > a span {
  top: 40px !important;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content a {
  height: 37px !important;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown {
  top: 63px !important;
}

//div.ReportViewer * > div.react-datepicker-wrapper > div > input {
div.ReportViewer * > div > input {
  //border-color: @cayuseBlue;
  // border-color: rgba(39, 41, 43, 0.15);
  border-color: @cayuseBlue;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  border-style: solid;
  box-shadow: rgba(0,0,0,0);
width:80%;
}

//#fm-toolbar > div > li > a > span {
//  display:none;
//}
