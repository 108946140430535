//@import '/semantic-ui/site/globals/site.variables';
//@import 'src/semantic-ui/site/globals/site.variables';
@import 'src/semantic-ui/site/globals/site.variables';
//.ui.table thead th {
//  background-color: @cayuseLightGray;
//}

//div.modal.header {
//  color: white;
//  background-color: @cayuseBlue;
//}

//div.ui.inverted.page.modals.dimmer.transition.visible.active > div > div.modal.header {
.NewReportModal {
  div.modal {
    color: white;
    background-color: @cayuseBlue;
  }

  .filter {
    margin-left: 30px;
    margin-top:8px;
    margin-bottom:8px;
    vertical-align: middle;
    font-size: medium;
  }

  .filter-label {
    color: black;
    font-size: medium;
    margin: 2px;
    width: 33%
  }

  input {
    //border-color: @cayuseBlue;
    // border-color: rgba(39, 41, 43, 0.15);
    border-color: #ddd;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-style: solid;
    box-shadow: rgba(0, 0, 0, 0%);
    margin-left: 20px;
    padding:4px;
    //width:80%;
  }
}
//.visible.transition {
//  display: unset !important;
//}
//
//.dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
//  display: -ms-flexbox;
//  display: flex !important;
//  opacity: 1;
//}

////$primaryColor: #0076b6;
////$primaryTextColor: #FFFFFF;
////$primaryBackgroundImage: none;
////$primaryTextShadow: none;
////$primaryBoxShadow: 0 0 0 0;
////$primaryColorHover: $primaryColor;
//
//.ReportsList {
//  width:100%;
//  height:100%;
//  background-color: white;
//  border: solid lightgray 2px;
//}
//
//.ReportsList .header {
//  width:100%;
//  text-align:  left;
//  border-bottom: solid #ddd 2px;
//  display: inline-block;
//  padding:3px;
//}
//
//.contents {
//  padding:9px;
//}
//
//.reportName {
//  vertical-align: center;
//  font-size: large;
//  float:left;
//  margin:6px;
//}
//
//.reportList {
//  padding:4px;
//}
//
//.nav-link {
//  display:inline;
//}
//
//div.buttons {
//  float:right;
//}
//
//.ui.primary.buttons .button,
//.ui.primary.button {
//  float:left;
//  margin-left: 3px;
//  margin-right: 3px;
//  line-height: 1;
//  padding:.78571em 1.5em;
//  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
//  border-radius: .2857rem;
//  background-color: $primaryColor;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//  background-image: $primaryBackgroundImage;
//}
//.ui.primary.button {
//  box-shadow: $primaryBoxShadow;
//}
//
//div.ui.primary.button i:before {
//  content:" + ";
//}
//
//.ui.primary.buttons .button:hover,
//.ui.primary.button:hover {
//  background-color: $primaryColorHover;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
//.ui.primary.buttons .button:active,
//.ui.primary.button:active {
//  //background-color: $primaryColorDown;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
//.ui.primary.buttons .active.button,
//.ui.primary.active.button {
//  //background-color: $primaryColorActive;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
