@import 'src/semantic-ui/site/globals/site.variables';

div.modal {
  color: white;
  background-color: @cayuseBlue;
}

.filter {
  margin: 2px;
  vertical-align: middle;
  font-size: medium;
}

.filter-label {
  color:black;
  font-size: medium;
  margin: 2px;
  width:33%
}

