/*body > div.ui.inverted.page.modals.dimmer.transition.visible.active > div > div.content > div {*/
div.content > div {
    color: black;
}



/*:global(.ui.modal).transparentModal {*/
/*    background-color: transparent;*/
/*    box-shadow: none;*/
/*    width: 50%;*/
/*}*/

/*:global(.ui.modal) > :global(.header).transparentModalHeader,*/
/*:global(.ui.modal) > :global(.content).transparentModalContent,*/
/*:global(.ui.modal) > :global(.actions).transparentModalActions {*/
/*    background-color: transparent;*/
/*    color: #ffffff;*/
/*}*/

/*.transparentModalContent {*/
/*    flex-direction: row !important;     !* todo: cleanup important *!*/
/*}*/

/*.transparentModalContentIcon {*/
/*    font-size: 5em !important;          !* todo: cleanup important *!*/
/*    margin-right: 0.25em !important;    !* todo: cleanup important *!*/
/*    margin-top: -0.25em !important;     !* todo: cleanup important *!*/
/*}*/

/*.transparentModalActionButtonPrimary {*/
/*    background-color: var(--color-sp-branded);*/
/*    border-color: var(--color-sp-branded);*/
/*    color: #ffffff;*/
/*}*/

/*.transparentModalActionButtonSecondary {*/
/*    background-color: transparent;*/
/*    border-color: #ffffff;*/
/*    color: #ffffff;*/
/*}*/
