/*******************************
     User Global Variables
*******************************/
/*@pageMinWidth  : 1049px;*/
/*@pageOverflowX : visible;*/
/*
@bigSize : (18 / 13);

@h1: 2.25em;

@defaultBorderRadius: 0.2307em;

@disabledOpacity: 0.3;
*/
/* Colors */
/* Links */
/* Borders */
/*
@borderColor: rgba(0, 0, 0, 0.13);
@solidBorderColor: #DDDDDD;
@internalBorderColor: rgba(0, 0, 0, 0.06);
@selectedBorderColor: #51A7E8;
*/
/* Breakpoints */
/*@largeMonitorBreakpoint: 1049px;

@computerBreakpoint: @largeMonitorBreakpoint;
@tabletBreakpoint: @largeMonitorBreakpoint;
*/
/*
@infoTextColor: #4E575B;
@warningTextColor: #613A00;
@errorTextColor: #991111;
*/
.NewReportModal div.modal {
  color: white;
  background-color: #0076b6;
}
.NewReportModal .filter {
  margin-left: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  vertical-align: middle;
  font-size: medium;
}
.NewReportModal .filter-label {
  color: black;
  font-size: medium;
  margin: 2px;
  width: 33%;
}
.NewReportModal input {
  border-color: #ddd;
  border-radius: 4px;
  border-width: 1px;
  box-sizing: border-box;
  border-style: solid;
  box-shadow: rgba(0, 0, 0, 0);
  margin-left: 20px;
  padding: 4px;
}
