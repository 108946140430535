.Reports {
  width: 100%;
  height: 100%;
  background-color: white;
}
.Reports .header {
  width: 100%;
  text-align: left;
  border-bottom: solid #ddd 2px;
  display: inline-block;
  padding: 3px;
}
.contents {
  padding: 9px;
}
.reportName {
  vertical-align: center;
  font-size: large;
  float: left;
  margin: 6px;
}
.reportList {
  padding: 4px;
}
.nav-link {
  display: inline;
}
div.buttons {
  float: right;
}
