/*******************************
     User Global Variables
*******************************/
/*@pageMinWidth  : 1049px;*/
/*@pageOverflowX : visible;*/
/*
@bigSize : (18 / 13);

@h1: 2.25em;

@defaultBorderRadius: 0.2307em;

@disabledOpacity: 0.3;
*/
/* Colors */
/* Links */
/* Borders */
/*
@borderColor: rgba(0, 0, 0, 0.13);
@solidBorderColor: #DDDDDD;
@internalBorderColor: rgba(0, 0, 0, 0.06);
@selectedBorderColor: #51A7E8;
*/
/* Breakpoints */
/*@largeMonitorBreakpoint: 1049px;

@computerBreakpoint: @largeMonitorBreakpoint;
@tabletBreakpoint: @largeMonitorBreakpoint;
*/
/*
@infoTextColor: #4E575B;
@warningTextColor: #613A00;
@errorTextColor: #991111;
*/
.visible.transition {
  display: unset !important;
}
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -ms-flexbox;
  display: flex !important;
  opacity: 1;
}
