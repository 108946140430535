//$primaryColor: #0076b6;
//$primaryTextColor: #FFFFFF;
//$primaryBackgroundImage: none;
//$primaryTextShadow: none;
//$primaryBoxShadow: 0 0 0 0;
//$primaryColorHover: $primaryColor;

.Reports {
  width:100%;
  height:100%;
  background-color: white;
}

.Reports .header {
  width:100%;
  text-align:  left;
  border-bottom: solid #ddd 2px;
  display: inline-block;
  padding:3px;
}

.contents {
  padding:9px;
}

.reportName {
  vertical-align: center;
  font-size: large;
  float:left;
  margin:6px;
}

.reportList {
  padding:4px;
}

.nav-link {
  display:inline;
}

div.buttons {
  float:right;
}

//.ui.primary.buttons .button,
//.ui.primary.button {
//  float:left;
//  margin-left: 3px;
//  margin-right: 3px;
//  line-height: 1;
//  padding:.78571em 1.5em;
//  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
//  border-radius: .2857rem;
//  background-color: $primaryColor;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//  background-image: $primaryBackgroundImage;
//}
//.ui.primary.button {
//  box-shadow: $primaryBoxShadow;
//}
//
//div.ui.primary.button i:before {
//  content:" + ";
//}
//
//.ui.primary.buttons .button:hover,
//.ui.primary.button:hover {
//  background-color: $primaryColorHover;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
//.ui.primary.buttons .button:active,
//.ui.primary.button:active {
//  //background-color: $primaryColorDown;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
//.ui.primary.buttons .active.button,
//.ui.primary.active.button {
//  //background-color: $primaryColorActive;
//  color: $primaryTextColor;
//  text-shadow: $primaryTextShadow;
//}
