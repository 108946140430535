/*******************************
     User Global Variables
*******************************/
/*@pageMinWidth  : 1049px;*/
/*@pageOverflowX : visible;*/
/*
@bigSize : (18 / 13);

@h1: 2.25em;

@defaultBorderRadius: 0.2307em;

@disabledOpacity: 0.3;
*/
/* Colors */
/* Links */
/* Borders */
/*
@borderColor: rgba(0, 0, 0, 0.13);
@solidBorderColor: #DDDDDD;
@internalBorderColor: rgba(0, 0, 0, 0.06);
@selectedBorderColor: #51A7E8;
*/
/* Breakpoints */
/*@largeMonitorBreakpoint: 1049px;

@computerBreakpoint: @largeMonitorBreakpoint;
@tabletBreakpoint: @largeMonitorBreakpoint;
*/
/*
@infoTextColor: #4E575B;
@warningTextColor: #613A00;
@errorTextColor: #991111;
*/
div.page-content {
  text-align: left;
  margin: 8px;
  border-radius: 3px;
  border: solid #ddd 3px;
  border-top: solid #0076b6 3px;
}
body {
  background-color: pink;
  font-size: 100%;
}
div.buttons {
  float: right;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
